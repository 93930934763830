<template>
    <div>
        <div id="page-wrapper" class="m-0 w-100">
            <div class="row">
                <b-navbar toggleable="md" variant="primary" type="dark" class="w-100">
                    <b-button v-b-toggle.sidebar variant="primary" class="text-lg-center">
                        <h4 class="mb-0"><font-awesome-icon icon="bars"></font-awesome-icon></h4>
                    </b-button>
                    <b-navbar-nav class="ml-auto">
						<b-nav-item v-if="sessionUser.system === 'SCOT_ADMIN'" style="border-right: 1px solid white; margin: 0 3px;">
							<b-badge pill variant="info" class="mr-3" style="cursor: default;" ><strong style="letter-spacing: 4px;">{{ $t('adminMode') }}</strong> </b-badge><strong style="color: #fff; margin-right: 4px; cursor: default;">{{ sessionUser.name }}</strong>
						</b-nav-item>
                        <b-nav-item class="mt-auto mb-auto" :active="language === 'en'" @click="changeLanguage('en')"
                                    style="font-size: 1.3em">
                            <strong>en</strong>
                        </b-nav-item>
                        <b-nav-item class="mt-auto mb-auto" :active="language === 'es'" @click="changeLanguage('es')"
                                    style="font-size: 1.3em">
                            <strong>es</strong>
                        </b-nav-item>
                        
                        <b-nav-item :active="true" @click="viewDetail" class="mt-auto mb-auto">
                            <span class="mr-2 d-none d-md-inline"></span>
                            <b-badge pill variant="warning" class="mr-3" v-if="user.status === 'TO_VERIFY'">{{ $t('toVerify') }}</b-badge>
                            <b-badge pill variant="danger" class="mr-3" v-else-if="user.status === 'REJECTED'">{{ $t('rejected') }}</b-badge>
                            <b-badge pill variant="info" class="mr-3" v-else-if="user.status === 'TO_UPDATE'">{{ $t('toUpdate') }}</b-badge>
                            <b-badge pill variant="success" class="mr-3" v-else-if="user.status === 'APPROVED'">{{ $t('active') }}</b-badge>
                            <b-badge pill variant="danger" class="mr-3" v-else>{{ $t('inactive') }}</b-badge>
                            <font-awesome-icon icon="user-circle"></font-awesome-icon> {{ user.username }}
                        </b-nav-item>

                        <b-nav-item @click="logOut" :active="true" v-b-tooltip.hover.bottom :title="$t('logOut')" class="mt-auto mb-auto">
                            <span class="ml-2 d-none d-md-inline"></span>
                            <font-awesome-icon icon="sign-out-alt"></font-awesome-icon> <span class="d-inline d-md-none">{{ $t('logOut') }}</span>
                        </b-nav-item>
                    </b-navbar-nav>
                </b-navbar>
            </div>
            <div class="row wrapper border-bottom foreground page-heading">
                <Breadcrumb :title="breadcrumb.title" :path="breadcrumb.path"></Breadcrumb>
            </div>
            <div class="container-fluid wrapper wrapper-content">
                <router-view @update-breadcrumb="updateBreadcrumb"></router-view>
            </div>
        </div>

        <Sidebar :menu="menu"></Sidebar>
    </div>
</template>

<script>
import Breadcrumb from '@cooper/src/components/bootstrap/Breadcrumb.vue';
import Sidebar from '../components/sidebar';
import { getUserData } from '@routes';
import { downloadAttachment } from '@/js/utilities';

export default {
    data() {
        return {
            user: {
                id: 0,
                name: 'User Name',
                email: '',
                username: '',
                roles: '',
                verified: false,
                status: '',
                supplierId: 0,
                supplierLegalName: '',
                reason: ''
            },
            breadcrumb: {
                title: '',
                path: []
            },
            menu: [
                {
                    title: this.$t('home'),
                    icon: 'home',
                    module: 'home',
                    route: { name: 'Home' }
                },
                {
                    title: this.$t('invoices'),
                    icon: 'file',
                    module: 'invoices',
                    route: { name: 'Invoices' }
                },
                {
                    title: this.$t('purchaseOrders'),
                    icon: 'shopping-cart',
                    module: 'PurchaseOrder',
                    route: { name: 'PurchaseOrder' }
                }
            ],
            token: [],
            language: '',
            sessionUser: {
                id: 0,
                name: '',
                email: '',
                username: '',
                system: ''
            }
        };
    },
    components: {
        Breadcrumb,
        Sidebar
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.token = JSON.parse(localStorage.getItem('token'));
        this.language = localStorage.getItem('language');
        this.sessionUser = JSON.parse(localStorage.getItem('sessionUser'));

        if (!this.token) {
            this.$router.replace({ name: 'Login' });
        }
    },
    methods: {
        logOut() {
            document.body.classList.remove('margin-left-sidebar');
            document.body.classList.add('no-margin-sidebar');

            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('actionUser');
            localStorage.setItem('language', this.language);
            
            this.$router.replace({ name: 'Login' });
        },
        updateBreadcrumb(breadcrumb) {
            this.breadcrumb = breadcrumb;
        },
        viewDetail() {
            localStorage.setItem('userAction', 'DETAIL');
            this.$router.replace({ name: 'Account' });
        },
        changeLanguage(value) {
            this.language = value;
            localStorage.setItem('language', this.language);
            location.reload();
        }
    }
};
</script>